import {
  AuthContextProps,
  UserLoginProps,
  History,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
}

interface TestConnectionProps extends FetchProps {}

export const testConnection = async (props: TestConnectionProps) => {
  const { sendRequest, auth } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/admins/test-connection`;
    await sendRequest(apiUrl, "GET", null, {});
  } catch (err) {}
};

///////// LOG USER //////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
interface LogUser extends FetchProps {
  email: string;
  password: string;
  history: History;
}

export const logUser = async (props: LogUser) => {
  const { sendRequest, auth, email, password, history } = props;

  try {
    const responseData: UserLoginProps = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/users/login`,
      "POST",
      JSON.stringify({
        email: email,
        password: password,
      }),
      {
        "Content-Type": "application/json",
      }
    );

    if (!responseData.admAccess) {
      auth.login(
        responseData.userId,
        responseData.email,
        responseData.personalData,
        responseData.managerId,
        responseData.managerUserId,
        responseData.vendors,
        responseData.token,
        responseData.type,
        responseData.isAdmin,
        responseData.statusOk,
        responseData.status,
        responseData.signupPlan,
        responseData.activeModules || [],
        responseData.whitelabel || "",
        responseData.azumeFinanceData,
        responseData.isSerranaCalcClient || false
      );
      localStorage.setItem("loginEmail", email);
      localStorage.setItem("loginPsd", password);
    } else {
      history.push(`/admin1?email=${email}&key=vrT%l-XhJ@dJ*Y78`);
    }
  } catch (err) {}
};

///////// LOG CLIENT WITH TOKEN /////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
interface LogClientWithToken extends FetchProps {
  token: string;
  isSerranaCalc: boolean;
  history: History;
}

export const logClientWithToken = async (props: LogClientWithToken) => {
  const { sendRequest, auth, token, isSerranaCalc, history } = props;

  try {
    const responseData: UserLoginProps = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/users/token-login`,
      "POST",
      JSON.stringify({}),
      {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    );

    auth.login(
      responseData.userId,
      responseData.email,
      responseData.personalData,
      responseData.managerId,
      responseData.managerUserId,
      responseData.vendors,
      responseData.token,
      responseData.type,
      responseData.isAdmin,
      responseData.statusOk,
      responseData.status,
      responseData.signupPlan,
      responseData.activeModules || [],
      responseData.whitelabel || "",
      responseData.azumeFinanceData,
      responseData.isSerranaCalcClient || false
    );

    if (isSerranaCalc) {
      history.push("/proposta/gerar-proposta/dados-consumo/nova");
    }
  } catch (err) {}
};
