import { useReducer, useEffect, FocusEvent } from "react";
import TextField from "@material-ui/core/TextField";

import { getInputChangeEvent } from "../../util/getInputChangeEvent";
import { validate } from "../../util/validators";

export interface InputDispState {
  value: string;
  isTouched: boolean;
  isValid: boolean;
}

export interface InputDispAction {
  type: string;
  val?: string;
  validators?: { type: string; val?: number }[];
}

const inputReducer = (
  state: InputDispState,
  action: InputDispAction
): InputDispState => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "CHANGE_CLEAN_GENERAL":
      return {
        ...state,
        value: /^[^<>|/\\?{}[\]$%^&*()!@#:;`¨=+~!]*$/.test(action.val)
          ? action.val
          : action.val.replace(/[<>|/\\?{}[\]$%^&*()!@#:;`¨=+~!]/g, ""),
        isValid: validate(action.val, action.validators),
      };
    case "CHANGE_CLEAN_MINIMAL":
      return {
        ...state,
        value: /^[^<>|\\?{}[\]$%^&*()!@#:;`¨=+~!]*$/.test(action.val)
          ? action.val
          : action.val.replace(/[<>|\\?{}[\]$%^&*()!@#:;`¨=+~!]/g, ""),
        isValid: validate(action.val, action.validators),
      };
    case "CHANGE_CLEAN_MESSAGE":
      return {
        ...state,
        value: /^[^\\^'`~]*$/.test(action.val)
          ? action.val
          : action.val.replace(/[\\^'`~]/g, ""),
        isValid: validate(action.val, action.validators),
      };
    case "CHANGE_CLEAN_NAME":
      return {
        ...state,
        value: /^[^<>|/\\?.,{}[\]_$%^&*()!@#:;'"¨`=+~\-!]*$/.test(action.val)
          ? action.val
          : action.val.replace(/[<>|/\\?.,{}[\]_$%^&*()!@#:;'"¨`=+~\-!]/g, ""),
        isValid: validate(action.val, action.validators),
      };
    case "CHANGE_CHAR_ONLY":
      return {
        ...state,
        value: /^[^<>|/\\?.,{}[\]_$%^&*()!@#:;'"¨`=+~\-!0-9]*$/.test(action.val)
          ? action.val
          : action.val.replace(
              /[<>|/\\?.,{}[\]_$%^&*()!@#:;'"¨`=+~\-!0-9]/g,
              ""
            ),
        isValid: validate(action.val, action.validators),
      };
    case "CHANGE_SLUG":
      return {
        ...state,
        value: /^[^<>_|/\\?.,{}[\]$%^&*()!@#:;'"`´¨=+~\!"  *"]*$/.test(
          action.val
        )
          ? action.val
          : action.val.replace(
              /[<>_|/\\?.,{}[\]$%^&*()!@#:;'"`´¨=+~\!"  *"]/g,
              ""
            ),
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "UNTOUCH":
      return {
        ...state,
        isTouched: false,
      };
    default:
      return state;
  }
};

interface GeneralInputProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  helperText: string;
  type: string;
  initialValue?: string;
  initialValid?: boolean;
  variant?: "standard" | "filled" | "outlined";
  forceError?: boolean;
  forceUpperCase?: boolean;
  isName?: boolean;
  isCharOnly?: boolean;
  isGeneral?: boolean;
  isMessage?: boolean;
  isSlug?: boolean;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  trackFocus?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  reset?: boolean;
  reinitialize?: boolean;
  validators: { type: string; val?: number }[];
  focusHandler?: (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  size?: "small" | "medium";
  onBlurHandler?: () => void;
  style?: {};
}

export const Input = (props: GeneralInputProps) => {
  const {
    id,
    label,
    onInput,
    forceError,
    helperText,
    type,
    initialValue,
    initialValid,
    variant,
    isName,
    isCharOnly,
    isGeneral,
    isMessage,
    isSlug,
    multiline,
    rows,
    placeholder,
    trackFocus,
    fullWidth,
    disabled,
    forceUpperCase,
    validators,
    reinitialize,
    reset,
    focusHandler,
    size = "medium",
    onBlurHandler,
    style = {},
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isTouched: false,
    isValid: initialValid || false,
  });
  const { value, isValid, isTouched } = inputState;

  useEffect(() => {
    onInput(
      id,
      forceUpperCase
        ? value
            .toUpperCase()
            .replace(/[<>|/\\?.,{}[\]_$%^&*()!@#:;'"`´=+~\-!]/g, "")
        : value,
      isValid,
      label.charAt(0).toUpperCase() + label.slice(1)
    );
  }, [
    id,
    value,
    isValid,
    label,
    onInput,
    forceUpperCase,
    isName,
    isGeneral,
    isMessage,
  ]);

  useEffect(() => {
    if (reset) {
      dispatch({
        type: "CHANGE",
        val: "",
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }

    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [reset, reinitialize, initialValue]);

  // useEffect(() => {
  //   if (initialValue) {
  //     dispatch({
  //       type: getInputChangeEvent({
  //         message: isMessage,
  //         name: isName,
  //         general: isGeneral,
  //       }),
  //       val: !forceUpperCase
  //         ? initialValue
  //         : initialValue
  //             .toUpperCase()
  //             .replace(/[<>|/\\?.,{}[\]_$%^&*()!@#:;'"`´=+~\-!]/g, ""),
  //       validators: validators,
  //     });
  //   }
  // }, [initialValue]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: getInputChangeEvent({
        message: isMessage,
        name: isName,
        general: isGeneral,
        isSlug: isSlug,
        charOnly: isCharOnly,
      }),
      val: event.target.value,
      validators: validators,
    });
  };

  const touchHandler = () => {
    dispatch({ type: "TOUCH" });
    if (onBlurHandler) {
      onBlurHandler();
    }
  };

  return (
    <TextField
      style={style}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      error={(!isValid && isTouched) || forceError}
      id={id}
      name={id}
      variant={variant}
      label={label.charAt(0).toUpperCase() + label.slice(1)}
      helperText={forceError ? helperText : !isValid && isTouched && helperText}
      multiline={multiline}
      rows={multiline && rows}
      placeholder={placeholder}
      inputProps={{
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();

            if (onBlurHandler) {
              onBlurHandler();
            }
          }
        },
        onChange: changeHandler,
        onBlur: touchHandler,
        onFocus: trackFocus && focusHandler,
        value: forceUpperCase
          ? value
              .toUpperCase()
              .replace(/[<>|/\\?.,{}[\]_$%^&*()!@#:;'"`´=+~\-!]/g, "")
          : value,
        type: type,
      }}
    />
  );
};
