import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import VideoLibraryRoundedIcon from "@material-ui/icons/VideoLibraryRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import ContactSupportRoundedIcon from "@material-ui/icons/ContactSupportRounded";
import NetworkWifiRoundedIcon from "@material-ui/icons/NetworkWifiRounded";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import BusinessCenterRoundedIcon from "@material-ui/icons/BusinessCenterRounded";
import AssignmentTurnedInRoundedIcon from "@material-ui/icons/AssignmentTurnedInRounded";
import IconButton from "@material-ui/core/IconButton";
import SystemUpdateAltRoundedIcon from "@material-ui/icons/SystemUpdateAltRounded";
import PersonAddRoundedIcon from "@material-ui/icons/PersonAddRounded";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import PostAddRoundedIcon from "@material-ui/icons/PostAddRounded";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import ConfirmationNumberRoundedIcon from "@material-ui/icons/ConfirmationNumberRounded";
import AppsRoundedIcon from "@material-ui/icons/AppsRounded";
import SupervisorAccountRoundedIcon from "@material-ui/icons/SupervisorAccountRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";

import { useHistory } from "react-router-dom";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { NotificationsMenu } from "../UIElements/NotificationsMenu";
import { ModalPurchaseModule } from "../UIElements/ModalPurchaseModule";
import { getAppStatusIcon, getAppStatusTitle } from "../../util/getAppStatus";
import { AboutTrialFormPopover } from "../../../general/components/unauthenticated/AboutTrialFormPopover";
import { DesktopNavMenu } from "./DesktopNavMenu";
import { AuthContext } from "../../context/authContext";
import { SettingsContext } from "../../context/settingsContext";
import { addToHomescreenPrompt } from "../../util/addToHomescreenPrompt";
import { authUserInAzumeFin } from "../../../user/api/azumeFinAPI";
import { getLoginCode } from "../../api/azumeFinAPI";
import { BeforeInstallPromptEvent } from "../../../App";
import { FinSoftwareScopes } from "../../../shared/data/types";
import "./DesktopNavLinksStyles.scss";

export interface DesktopNavLinksProps {
  addToHomescreenEvent: BeforeInstallPromptEvent;
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >;
  needUpdate: boolean;
  needCriticalUpdate: boolean;
  updateAvailable: boolean;
}

export const DesktopNavLinks = (props: DesktopNavLinksProps) => {
  const {
    addToHomescreenEvent,
    setAddToHomescreenEvent,
    needCriticalUpdate,
    needUpdate,
    updateAvailable,
  } = props;
  const auth = useContext(AuthContext);
  const { sendRequest, isLoading } = useHttpClient();
  const settingsCtx = useContext(SettingsContext);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<
    null | HTMLButtonElement | HTMLSpanElement
  >(null);
  const [showPurchaseModal, setShowPurchaseModule] = useState<boolean>(false);
  const [loadedData, setLoadedData] = useState<string>("");
  const isConfigManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.configManager || false;
  const [authData, setAuthData] = useState<{
    isAuth: boolean;
    isFullScope: boolean;
    scope: FinSoftwareScopes[];
    message: string;
  }>(null);
  const [isOpenApps, setIsOpenApps] = useState<boolean>(false);

  useEffect(() => {
    if (auth?.azumeFinanceData?.status === "ACTIVE" && isOpenApps) {
      if (!authData && auth?.type === "VENDOR") {
        authUserInAzumeFin({ sendRequest, auth, setAuthData });
      } else {
        getLoginCode({
          sendRequest,
          auth,
          setLoadedData,
        });
      }
    }
  }, [isOpenApps, authData?.isAuth]);

  const logInAzumeFin = () => {
    if (auth?.azumeFinanceData?.status === "ACTIVE") {
      window.open(
        `https://autenticacao.azume.app/login/${loadedData}`,
        "_blank"
      );
    } else {
      setShowPurchaseModule(true);
    }
  };

  return (
    <ul className="nav-links">
      <ModalPurchaseModule
        open={showPurchaseModal}
        setOpen={setShowPurchaseModule}
        title="LIBERE O AZUME FINANCEIRO!"
        content="O Azume Financeiro é uma plataforma especializada na gestão financeira de empresas de Energia Solar Fotovoltaica, integrada ao CRM de Energia Solar. Para acessar o Azume Financeiro, você precisa liberar a plataforma. Deseja fazer isso agora?"
        whatsAppMsg="Quero liberar o Azume Financeiro"
      />
      <AboutTrialFormPopover
        id="popover-contact-video-section"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      {!auth.isLoggedIn && (
        <li className="nav-link-vanish">
          <NavLink to="/sobre">
            <Button color="inherit">SOBRE</Button>
          </NavLink>
        </li>
      )}
      {!auth.isLoggedIn && (
        <li className="nav-link-vanish">
          <Button
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            color="inherit"
          >
            CONTATO
          </Button>
        </li>
      )}
      {!auth.isLoggedIn && (
        <li className="nav-link-vanish">
          <NavLink to="/login">
            <Button color="inherit">LOGIN</Button>
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && !!addToHomescreenEvent && (
        <li className="nav-link-vanish nav-link-vanish--highlighted">
          <Button
            color="inherit"
            onClick={() => {
              addToHomescreenPrompt(
                addToHomescreenEvent,
                setAddToHomescreenEvent
              );
            }}
          >
            <span className="nav-link-vanish-text">INSTALAR</span> &nbsp;{" "}
            <SystemUpdateAltRoundedIcon />
          </Button>
        </li>
      )}
      {auth.isLoggedIn && (
        <li className="nav-link-vanish">
          <NavLink to="/inicio">
            <Button color="inherit" onClick={() => {}}>
              INÍCIO
            </Button>
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn &&
        !auth.userIsAdmin &&
        auth.signupPlan !== "BASIC" &&
        (settingsCtx.individualPermissions
          ?.filter((ip) => {
            return ip.salesKanban;
          })
          ?.map((ip) => {
            return ip?.user;
          })
          .includes(auth.userId) ||
          auth.type !== "VENDOR") && (
          <li className="nav-link-vanish" onClick={() => {}}>
            <DesktopNavMenu
              menuTitle="NEGÓCIOS"
              textItem1="Registrar Cliente"
              textItem2="Meus Clientes"
              textItem3="Gerar Proposta"
              textItem4="Minhas Propostas"
              textItem5="Funil de Vendas"
              textItem6="Vendas Finalizadas"
              link1="/cliente/cadastro"
              link2={`/clientes/lista/${auth.userId}`}
              link3="/proposta/gerar-proposta/dados-consumo/nova"
              link4={`/propostas/lista/${auth.userId}`}
              link5={`/vendas/funil/${auth.userId}`}
              link6={`/vendas/finalizadas`}
              icon1={<PersonAddRoundedIcon fontSize="small" />}
              icon2={<GroupRoundedIcon fontSize="small" />}
              icon3={<PostAddRoundedIcon fontSize="small" />}
              icon4={<ListAltRoundedIcon fontSize="small" />}
              icon5={<NetworkWifiRoundedIcon fontSize="small" />}
              icon6={<AssignmentTurnedInRoundedIcon fontSize="small" />}
            />
          </li>
        )}
      {/* {auth.isLoggedIn && !auth.userIsAdmin && (
        <li
          onClick={() => {
            addToHomescreenPrompt(
              addToHomescreenEvent,
              setAddToHomescreenEvent
            );
          }}
        >
          <DesktopNavMenu
            menuTitle="CLIENTES"
            textItem1="Meus Clientes"
            textItem2="Registrar Cliente"
            textItem3="Exportar Clientes"
            textItem4="Upload de Clientes"
            link1={`/clientes/lista/${auth.userId}`}
            link2="/cliente/cadastro"
            function3={exportCustomerssData}
            link4="/upload-de-clientes"
            icon1={<GroupRoundedIcon fontSize="small" />}
            icon2={<PersonAddRoundedIcon fontSize="small" />}
            icon3={<FileCopyRoundedIcon fontSize="small" />}
            icon4={<BackupRoundedIcon fontSize="small" />}
          />
        </li>
      )} */}
      {/* {auth.isLoggedIn && !auth.userIsAdmin && (
        <li
          onClick={() => {
            addToHomescreenPrompt(
              addToHomescreenEvent,
              setAddToHomescreenEvent
            );
          }}
        >
          <DesktopNavMenu
            menuTitle="KITS"
            textItem1="Meus Kits"
            textItem2="Registrar Kit"
            textItem3="Upload de Kits"
            link1={`/kits/lista/${auth.userId}`}
            link2={
              auth.type !== "VENDOR" ||
              !settingsCtx.preferences?.vendors?.useManagerKits
                ? "/kits/cadastro/0"
                : null
            }
            link3={
              auth.type !== "VENDOR" ||
              !settingsCtx.preferences?.vendors?.useManagerKits
                ? "/upload-de-kits"
                : null
            }
            icon1={<ShoppingCartRoundedIcon fontSize="small" />}
            icon2={<AddShoppingCartRoundedIcon fontSize="small" />}
            icon3={<BackupRoundedIcon fontSize="small" />}
          />
        </li>
      )} */}
      {/* {auth.isLoggedIn && !auth.userIsAdmin && (
        <li
          onClick={() => {
            addToHomescreenPrompt(
              addToHomescreenEvent,
              setAddToHomescreenEvent
            );
          }}
        >
          <DesktopNavMenu
            menuTitle="PROPOSTAS"
            textItem1="Minhas Propostas"
            textItem2="Nova Proposta"
            textItem3={auth.type !== "VENDOR" ? "Personalização" : undefined}
            link1={`/propostas/lista/${auth.userId}`}
            link2="/proposta/gerar-proposta/dados-consumo/nova"
            link3={
              auth.type !== "VENDOR"
                ? `/personalizar-proposta/${auth.userId}`
                : undefined
            }
            icon1={<ListAltRoundedIcon fontSize="small" />}
            icon2={<PostAddRoundedIcon fontSize="small" />}
            icon3={
              auth.type !== "VENDOR" ? (
                <ColorLensRoundedIcon fontSize="small" />
              ) : undefined
            }
          />
        </li>
      )} */}
      {auth.isLoggedIn && (
        <IconButton
          className="nav-link-vanish"
          title={getAppStatusTitle(
            needCriticalUpdate,
            needUpdate,
            updateAvailable
          )}
          color="inherit"
          onClick={() => {
            history.push("/atualizacoes");
          }}
        >
          {getAppStatusIcon(needCriticalUpdate, needUpdate, updateAvailable)}
        </IconButton>
      )}
      {auth.isLoggedIn && !auth.userIsAdmin && (
        <li onClick={() => {}}>
          <DesktopNavMenu
            iconButtonIcon={<AppsRoundedIcon />}
            menuTitle="Plataformas"
            textItem1={!auth?.whitelabel ? "Azume CRM" : "Gestão Solar"}
            textItem2="Azume Financeiro"
            link1="/inicio"
            function2={logInAzumeFin}
            // disabled2
            icon1={<SupervisorAccountRoundedIcon fontSize="small" />}
            icon2={<AttachMoneyRoundedIcon fontSize="small" />}
            setExtIsOpen={setIsOpenApps}
            isLoading={isLoading}
          />
        </li>
      )}
      <NotificationsMenu />
      {auth.isLoggedIn && !auth.userIsAdmin && (
        <li onClick={() => {}}>
          <DesktopNavMenu
            profile
            menuTitle="CONTA"
            textItem1="Dados da Empresa"
            textItem2="Curso Azume"
            textItem3="Financeiro"
            textItem4="Configurações"
            textItem5="Chamar Suporte"
            textItem7="Tickets de Suporte"
            textItem6="Sair"
            link1={`/conta/info/${auth.userId}`}
            // link2="/tutoriais"
            link3="/financeiro"
            link4="/configuracoes"
            link5="/contato"
            link7="/tickets"
            link6="user-loggout"
            icon1={<BusinessCenterRoundedIcon fontSize="small" />}
            icon2={<VideoLibraryRoundedIcon fontSize="small" />}
            icon3={<MonetizationOnRoundedIcon fontSize="small" />}
            icon4={<SettingsIcon fontSize="small" />}
            icon5={<ContactSupportRoundedIcon fontSize="small" />}
            icon7={<ConfirmationNumberRoundedIcon fontSize="small" />}
            icon6={<ExitToAppRoundedIcon fontSize="small" />}
            isConfigManager={isConfigManager}
          />
        </li>
      )}
    </ul>
  );
};
