import React, { useContext, useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { NavLink } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";

import { AuthContext } from "../../context/authContext";
import { NavContext } from "../../context/navContext";
import { useNav } from "../../hooks/navHook";
import { SettingsContext } from "../../../shared/context/settingsContext";
import { SideMenu } from "./SideMenu";
import { SideDrawer } from "./SideDrawer";
import { DesktopNavLinks } from "./DesktopNavLinks";
import { NotificationsMenu } from "../UIElements/NotificationsMenu";
import { getAppStatusIcon, getAppStatusTitle } from "../../util/getAppStatus";
import { useHttpClient } from "../../../shared/hooks/httpHook";
import { useQuery } from "../../../shared/util/useQuery";
import { getUserSettings } from "../../../general/api/homePageUserAPI";
import { getWebsiteTemplate } from "../../../website/api/websiteAPI";
import { BeforeInstallPromptEvent } from "../../../App";
import { WebsiteData } from "../../data/types";

import { MainNavigationStyles } from "./MainNavigationStyles";

interface MainNavigationProps {
  addToHomescreenEvent: BeforeInstallPromptEvent;
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >;
  needUpdate: boolean;
  needCriticalUpdate: boolean;
}

export const MainNavigation = (props: MainNavigationProps) => {
  const {
    addToHomescreenEvent,
    setAddToHomescreenEvent,
    needUpdate,
    needCriticalUpdate,
  } = props;
  const auth = useContext(AuthContext);
  const settingsCtx = useContext(SettingsContext);
  const location = useLocation();
  const isSerranaCalc = useQuery()?.get("isSerranaCalc") || "false";

  const {
    activeView,
    activeUrl,
    expand,
    hide,
    navigateHandler,
    expandHandler,
    hideHandler,
  } = useNav({
    pathName: location.pathname,
  });
  const { sendRequest } = useHttpClient();
  const classes = MainNavigationStyles();
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const [updateAvailable, setUpdateAvailable] = useState<boolean>(false);
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [website, setWebsite] = useState<WebsiteData>(null);
  const [mainNavProps, setMainNavProps] = useState({
    light: false,
    scrollTop: false,
    whatsapp: "",
    disable: false,
  });

  useEffect(() => {
    if (auth.isLoggedIn) {
      getUserSettings({ sendRequest, settingsCtx, auth, setSettingsLoaded });
    }
  }, [sendRequest, auth.isLoggedIn]);

  useEffect(() => {
    if (auth.isLoggedIn && auth.type === "MANAGER") {
      getWebsiteTemplate({
        sendRequest,
        uid: auth.userId,
        setLoadedWebsite: setWebsite,
      });
    }
  }, [sendRequest, auth.isLoggedIn, auth.type]);

  useEffect(() => {
    if (location.pathname.includes("/sobre")) {
      setMainNavProps((prevValues) => {
        return {
          ...prevValues,
          light: true,
          scrollTop: true,
          whatsapp: "salesAndZoom",
          disable: false,
        };
      });
    } else if (
      location.pathname.includes("/proposta/final") ||
      location.pathname.includes("/assinatura") ||
      location.pathname.includes("/institucional") ||
      location.pathname.includes("/simulador") ||
      location.pathname.includes("/form-teste-gratis") ||
      location.pathname.includes("/form-cadastro-curso") ||
      location.pathname.includes("/pedido/") ||
      location.pathname.includes("chat-solicitacao-teste") ||
      auth.isSerranaCalcClient ||
      isSerranaCalc === "true"
    ) {
      setMainNavProps((prevValues) => {
        return { ...prevValues, disable: true };
      });
    } else if (
      location.pathname.includes("/inicio") ||
      location.pathname.includes("/financeiro") ||
      location.pathname.includes("/configuracoes") ||
      location.pathname.includes("/recuperacao-de-acesso")
    ) {
      setMainNavProps((prevValues) => {
        return {
          ...prevValues,
          whatsapp: "support",
          light: false,
          scrollTop: false,
          disable: false,
        };
      });
    } else {
      setMainNavProps({
        light: false,
        scrollTop: false,
        whatsapp: "",
        disable: false,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      const updateAvailable =
        (localStorage.getItem("updateStatus") as "UPDATED" | "AVAILABLE") ===
        "AVAILABLE";
      setUpdateAvailable(updateAvailable);
    }, 1000 * 3);
    const timer2 = setTimeout(() => {
      const updateAvailable =
        (localStorage.getItem("updateStatus") as "UPDATED" | "AVAILABLE") ===
        "AVAILABLE";
      setUpdateAvailable(updateAvailable);
    }, 1000 * 6);
    const timer3 = setTimeout(() => {
      const updateAvailable =
        (localStorage.getItem("updateStatus") as "UPDATED" | "AVAILABLE") ===
        "AVAILABLE";
      setUpdateAvailable(updateAvailable);
    }, 1000 * 9);
    const timer5 = setTimeout(() => {
      const updateAvailable =
        (localStorage.getItem("updateStatus") as "UPDATED" | "AVAILABLE") ===
        "AVAILABLE";
      setUpdateAvailable(updateAvailable);
    }, 1000 * 12);
    const timer4 = setTimeout(() => {
      const updateAvailable =
        (localStorage.getItem("updateStatus") as "UPDATED" | "AVAILABLE") ===
        "AVAILABLE";
      setUpdateAvailable(updateAvailable);
    }, 1000 * 15);
    const timer6 = setTimeout(() => {
      const updateAvailable =
        (localStorage.getItem("updateStatus") as "UPDATED" | "AVAILABLE") ===
        "AVAILABLE";
      setUpdateAvailable(updateAvailable);
    }, 1000 * 20);
    const timer7 = setTimeout(() => {
      const updateAvailable =
        (localStorage.getItem("updateStatus") as "UPDATED" | "AVAILABLE") ===
        "AVAILABLE";
      setUpdateAvailable(updateAvailable);
    }, 1000 * 30);
    const timer8 = setTimeout(() => {
      const updateAvailable =
        (localStorage.getItem("updateStatus") as "UPDATED" | "AVAILABLE") ===
        "AVAILABLE";
      setUpdateAvailable(updateAvailable);
    }, 1000 * 60);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      clearTimeout(timer5);
      clearTimeout(timer6);
      clearTimeout(timer7);
      clearTimeout(timer8);
    };
  }, []);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const closeDrawerTimerHandler = () => {
    setTimeout(() => {
      setDrawerIsOpen(false);
    }, 200);
  };

  if (mainNavProps.disable) {
    return null;
  }

  return (
    <header
      className={classes.root + `${hide ? ` ${classes.root}--hidden` : ""}`}
    >
      <NavContext.Provider
        value={{
          activeView: activeView,
          activeUrl: activeUrl,
          expand,
          hide,
          navigateHandler: navigateHandler,
          expandHandler,
          hideHandler,
        }}
      >
        <React.Fragment>
          <SideDrawer
            anchor="left"
            open={drawerIsOpen}
            onClose={closeDrawerHandler}
            clickCloser={closeDrawerTimerHandler}
            classes={classes.mobileNavlinks}
            addToHomescreenEvent={addToHomescreenEvent}
            setAddToHomescreenEvent={setAddToHomescreenEvent}
            slug={website?.slug || ""}
          />
          <AppBar
            id="de-volta-ao-inicio"
            position="fixed"
            elevation={1}
            className={classes.appBar}
          >
            <div className={classes.navbarContentPadding}>
              <Toolbar>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={openDrawerHandler}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h4" className={classes.title}>
                  <NavLink to="/inicio">
                    {auth.whitelabel !== "SERRANA" && (
                      <picture className="nav-links__logo">
                        <source
                          srcSet={`${process.env.PUBLIC_URL}/azume-logo-app-light.webp`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${process.env.PUBLIC_URL}/azume-logo-app-light.png`}
                          type="image/png"
                        />
                        <img
                          className="nav-links__logo"
                          alt="Logo Azume"
                          src={`${process.env.PUBLIC_URL}/azume-logo-app-light.png`}
                        />
                      </picture>
                    )}
                    {auth.whitelabel === "SERRANA" && (
                      <img
                        className="nav-links__logo"
                        alt="Logo Gestão Solar"
                        src={`${process.env.PUBLIC_URL}/logo-gestao-solar-azume.png`}
                      />
                    )}
                  </NavLink>
                </Typography>
                <div className={classes.navOpts}>
                  {auth.isLoggedIn && (
                    <div
                      className={
                        classes.navOptBtn +
                        `${
                          hide
                            ? ` ${classes.navOptBtn}--active ${classes.navOptBtn}--hidden`
                            : ""
                        }`
                      }
                      title={!hide ? "Esconder" : "Expandir"}
                      onClick={() => {
                        hideHandler(!hide);
                      }}
                    >
                      <NavigateBeforeRoundedIcon />
                    </div>
                  )}
                  {auth.isLoggedIn && (
                    <div
                      className={
                        classes.navOptBtn +
                        `${expand ? ` ${classes.navOptBtn}--active` : ""}` +
                        `${hide ? ` ${classes.navOptBtn}--hidden` : ""}`
                      }
                      title={expand ? "Esconder" : "Expandir"}
                      onClick={() => {
                        expandHandler(!expand);
                      }}
                    >
                      <KeyboardArrowDownRoundedIcon />
                    </div>
                  )}
                </div>
                <nav className={classes.desktopNavlinks}>
                  <DesktopNavLinks
                    addToHomescreenEvent={addToHomescreenEvent}
                    setAddToHomescreenEvent={setAddToHomescreenEvent}
                    needCriticalUpdate={needCriticalUpdate}
                    needUpdate={needUpdate}
                    updateAvailable={updateAvailable}
                  />
                </nav>
                {!mainNavProps.light && (
                  <SideMenu
                    addToHomescreenEvent={addToHomescreenEvent}
                    setAddToHomescreenEvent={setAddToHomescreenEvent}
                    hide={hide}
                    slug={website?.slug || ""}
                  />
                )}
              </Toolbar>
            </div>
          </AppBar>
          {/* {mainNavProps.whatsapp === "support" && auth.status === "ACTIVE" && (
            <ButtonWhatsApp />
          )} */}
          {/* {mainNavProps.whatsapp === "salesAndZoom" && (
            <ButtonWhatsAppForm zoom />
          )}
          {mainNavProps.whatsapp === "sales" && <ButtonWhatsAppForm />} */}
        </React.Fragment>
      </NavContext.Provider>
    </header>
  );
};
