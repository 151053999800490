import CircularProgress from "@material-ui/core/CircularProgress";

import { LoadingSpinnerOverlayRegularStyles } from "./LoadingSpinnerOverlayRegularStyles";

interface LoadingSpinnerOverlayRegularProps {
  image?: boolean;
  infoCard?: boolean;
  fixed?: boolean;
  fixedNoNav?: boolean;
  color?: "inherit" | "primary" | "secondary";
  style?: {};
  size?: number;
}

export const LoadingSpinnerOverlayRegular = (
  props: LoadingSpinnerOverlayRegularProps
) => {
  const { color, image, infoCard, style, fixed, fixedNoNav, size } = props;
  const classes = LoadingSpinnerOverlayRegularStyles();

  if (image) {
    return (
      <div style={style} className={classes.image}>
        <CircularProgress color={color} />
      </div>
    );
  }

  if (infoCard) {
    return (
      <div style={style} className={classes.infoCard}>
        <CircularProgress color={color} />
      </div>
    );
  }

  if (fixed) {
    return (
      <div style={style} className={classes.fixed}>
        <CircularProgress color={color} />
      </div>
    );
  }

  if (fixedNoNav) {
    return (
      <div style={style} className={classes.fixedNoNav}>
        <CircularProgress color={color} />
      </div>
    );
  }

  if (!!size) {
    return (
      <div style={style} className={classes.image}>
        <CircularProgress size={size} color={color} />
      </div>
    );
  }

  return (
    <div style={style} className={classes.root}>
      <CircularProgress color={color} />
    </div>
  );
};
