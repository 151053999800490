import { getProportionalPrice } from "../../shared/util/getProportionalPrice";
import {
  StringNumbers,
  SignupPricesAndTaxesData,
} from "../../shared/data/types";

export const getPlanPriceById = (
  planId: StringNumbers,
  loadedPrices: SignupPricesAndTaxesData,
  discountMultiplier: number | null,
  vendorsAmount: number = 2,
  isFirstPurchase: boolean,
  isUpgrade: boolean = false,
  validityDate?: string,
  currentAcVendors: number = 1
) => {
  let price = 0;
  let enterpriseBasePricePerVendor = loadedPrices.enterprise;

  if (vendorsAmount <= 4) {
    enterpriseBasePricePerVendor = loadedPrices.enterprise;
  } else if (vendorsAmount > 4 && vendorsAmount <= 9) {
    enterpriseBasePricePerVendor = loadedPrices.enterprise5;
  } else if (vendorsAmount > 9 && vendorsAmount <= 19) {
    enterpriseBasePricePerVendor = loadedPrices.enterprise10;
  } else if (vendorsAmount > 19) {
    enterpriseBasePricePerVendor = loadedPrices.enterprise20;
  }

  if (planId === "1") {
    price = !!discountMultiplier
      ? loadedPrices.simple * discountMultiplier
      : loadedPrices.simple;
  } else if (planId === "2") {
    price = !!discountMultiplier
      ? loadedPrices.crm * discountMultiplier
      : loadedPrices.crm;
  } else if (planId === "3") {
    if (!isUpgrade) {
      price = !!discountMultiplier
        ? enterpriseBasePricePerVendor * discountMultiplier * vendorsAmount
        : enterpriseBasePricePerVendor * vendorsAmount;
    } else {
      price = getProportionalPrice(
        enterpriseBasePricePerVendor,
        discountMultiplier || 1,
        vendorsAmount - currentAcVendors,
        validityDate
      );
    }
  }

  if (isFirstPurchase && !isUpgrade) {
    if (planId === "3") {
      if (vendorsAmount <= 4) {
        price += (loadedPrices.digSign20 || 0) * 20;
        price += loadedPrices.standardConfig;
      }

      if (vendorsAmount >= 5) {
        price += loadedPrices.premiumConfig;
      }

      if (vendorsAmount >= 10) {
        price += loadedPrices.prop20;
      }
    } else {
      price += loadedPrices.standardConfig;
    }
  }

  return price;
};
