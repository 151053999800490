import React, { useState, useContext, Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";

import { MenuCustomCenterBottom } from "../MUIThemed/MenuCustomCenterBottom";

import { LoadingSpinnerOverlayRegular } from "../../components/UIElements/LoadingSpinnerOverlayRegular";
import { ModalError } from "../../components/UIElements/ModalError";
import { useHttpClient } from "../../hooks/httpHook";
import { MenuItemCustomBlueFocusBackground } from "../MUIThemed/MenuItemCustomBlueFocusBackground";

import { AuthContext } from "../../context/authContext";
import { FetchProps } from "../../../customer/api/customersAPI";

import "./DesktopNavMenu.scss";

interface DesktopNavMenuProps {
  menuTitle: string;
  iconBtn?: boolean;
  profile?: boolean;
  textItem1: string;
  textItem2?: string;
  textItem3?: string;
  textItem4?: string;
  textItem5?: string;
  textItem6?: string;
  textItem7?: string;
  link1?: string;
  link2?: string;
  link3?: string;
  link4?: string;
  link5?: string;
  link6?: string;
  link7?: string;
  function1?: () => void;
  function2?: () => void;
  function3?: (props: FetchProps) => Promise<void>;
  icon1: JSX.Element;
  icon2?: JSX.Element;
  icon3?: JSX.Element;
  icon4?: JSX.Element;
  icon5?: JSX.Element;
  icon6?: JSX.Element;
  icon7?: JSX.Element;
  disabled2?: boolean;
  isConfigManager?: boolean;
  iconButtonIcon?: JSX.Element;
  isLoading?: boolean;
  setExtIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DesktopNavMenu = (props: DesktopNavMenuProps) => {
  const {
    menuTitle,
    iconBtn,
    profile,
    textItem1,
    textItem2,
    textItem3,
    textItem4,
    textItem5,
    textItem6,
    textItem7,
    link1,
    link2,
    link3,
    link4,
    link5,
    link6,
    link7,
    function1,
    function2,
    function3,
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
    icon7,
    disabled2,
    isConfigManager = false,
    iconButtonIcon,
    isLoading = false,
    setExtIsOpen,
  } = props;
  const auth = useContext(AuthContext);
  const router = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    if (setExtIsOpen) {
      setExtIsOpen(!!anchorEl);
    }
  }, [setExtIsOpen, anchorEl]);

  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <div>
        {!iconButtonIcon && !iconBtn && !profile && (
          <Button color="inherit" onClick={clickHandler}>
            {menuTitle}
          </Button>
        )}
        {!iconButtonIcon && iconBtn && !profile && (
          <IconButton title={menuTitle} color="inherit" onClick={clickHandler}>
            <AccountCircleRoundedIcon />
          </IconButton>
        )}
        {!!iconButtonIcon && !profile && !iconBtn && (
          <IconButton title={menuTitle} color="inherit" onClick={clickHandler}>
            {iconButtonIcon}
          </IconButton>
        )}
        {!iconButtonIcon && profile && (
          <IconButton
            title={`${auth.personalData?.name} - ${auth.email}`}
            color="inherit"
            onClick={clickHandler}
          >
            <div className="desktop-nav-menu__avatar">
              {auth.personalData?.name?.split(" ")?.length <= 1 ? (
                <span>{auth.personalData?.name?.substring(0, 2) || "AZ"}</span>
              ) : (
                <span>
                  {auth.personalData?.name?.split(" ")?.[0]?.substring(0, 1) ||
                    "A"}
                  {auth.personalData?.name?.split(" ")?.[1]?.substring(0, 1) ||
                    "Z"}
                </span>
              )}
            </div>
          </IconButton>
        )}
        <MenuCustomCenterBottom
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeHandler}
        >
          {isLoading && <LoadingSpinnerOverlayRegular size={35} />}
          {!iconButtonIcon && profile && (
            <div className="desktop-nav-menu__account-info">
              <p className="desktop-nav-menu__account-name">
                {auth.personalData?.name}
              </p>
              <p className="desktop-nav-menu__account-type">
                {auth.type === "MANAGER" ? "Dono da Conta" : "Colaborador"}
              </p>
              <p className="desktop-nav-menu__account-email">{auth.email}</p>
            </div>
          )}
          {link1 && (
            <NavLink to={link1}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon1}</ListItemIcon>
                <ListItemText primary={textItem1} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
          {function1 && (
            <MenuItemCustomBlueFocusBackground disabled onClick={function1}>
              <ListItemIcon>{icon1}</ListItemIcon>
              <ListItemText primary={textItem1} />
            </MenuItemCustomBlueFocusBackground>
          )}
          {link2 && (
            <NavLink to={link2}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon2}</ListItemIcon>
                <ListItemText primary={textItem2} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
          {function2 && (
            <MenuItemCustomBlueFocusBackground
              disabled={disabled2}
              onClick={function2}
            >
              <ListItemIcon>{icon2}</ListItemIcon>
              <ListItemText primary={textItem2} />
            </MenuItemCustomBlueFocusBackground>
          )}
          {function3 && auth.type !== "VENDOR" && (
            <div
              onClick={() => {
                if (!isLoading) {
                  function3({ auth, sendRequest });
                }
              }}
            >
              <MenuItemCustomBlueFocusBackground disabled={isLoading}>
                <ListItemIcon>{icon3}</ListItemIcon>
                <ListItemText primary={textItem3} />
              </MenuItemCustomBlueFocusBackground>
            </div>
          )}
          {link3 && (
            <NavLink to={link3}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon3}</ListItemIcon>
                <ListItemText primary={textItem3} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
          {link4 &&
            (textItem4 !== "Upload de Clientes" || auth.type !== "VENDOR") &&
            (!link4.includes("/configuracoes") ||
              (link4.includes("/configuracoes") &&
                (auth.type !== "VENDOR" || isConfigManager))) && (
              <NavLink to={link4}>
                <MenuItemCustomBlueFocusBackground>
                  <ListItemIcon>{icon4}</ListItemIcon>
                  <ListItemText primary={textItem4} />
                </MenuItemCustomBlueFocusBackground>
              </NavLink>
            )}
          {link5 && (
            <NavLink to={link5}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon5}</ListItemIcon>
                <ListItemText primary={textItem5} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
          {link7 && (
            <NavLink to={link7}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon7}</ListItemIcon>
                <ListItemText primary={textItem7} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
          {link6 && link6 === "user-loggout" && (
            <MenuItemCustomBlueFocusBackground
              onClick={() => {
                auth.logout();
                router.push("/");
              }}
            >
              <ListItemIcon>{icon6}</ListItemIcon>
              <ListItemText primary={textItem6} />
            </MenuItemCustomBlueFocusBackground>
          )}
          {link6 && link6 !== "user-loggout" && (
            <NavLink to={link6}>
              <MenuItemCustomBlueFocusBackground>
                <ListItemIcon>{icon6}</ListItemIcon>
                <ListItemText primary={textItem6} />
              </MenuItemCustomBlueFocusBackground>
            </NavLink>
          )}
        </MenuCustomCenterBottom>
      </div>
    </Fragment>
  );
};
