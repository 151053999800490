import {
  AuthContextProps,
  FinSoftwareScopes,
  UserData,
  AzumeFinOrganizationData,
  AzumeFinBankAccountData,
  AzumeFinCostCenterData,
  AzumeFinCategoryData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET LOGIN CODE ///////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
interface GetLoginCodeProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<string>>;
}

export const getLoginCode = async (props: GetLoginCodeProps) => {
  const { sendRequest, auth, setLoadedData } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/azume-financial/login-code`;
    const responseData: {
      data: string;
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setLoadedData(responseData.data || "");
  } catch (err) {}
};
