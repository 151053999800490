import {
  AuthContextProps,
  FinSoftwareScopes,
  UserData,
  AzumeFinOrganizationData,
  AzumeFinBankAccountData,
  AzumeFinCostCenterData,
  AzumeFinCategoryData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// AUTH USER IN AZUME FIN ///////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
interface AuthUserInAzumeFinProps extends FetchProps {
  setAuthData: React.Dispatch<
    React.SetStateAction<{
      isAuth: boolean;
      isFullScope: boolean;
      scope: FinSoftwareScopes[];
      message: string;
    }>
  >;
}

export const authUserInAzumeFin = async (props: AuthUserInAzumeFinProps) => {
  const { sendRequest, auth, setAuthData } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/azume-financial/auth-external`;
    const responseData: {
      user: UserData;
      isAuth: boolean;
      isFullScope: boolean;
      scope: FinSoftwareScopes[];
      message: string;
    } = await sendRequest(apiUrl, "POST", JSON.stringify({}), {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.token,
    });

    setAuthData(responseData);
  } catch (err) {}
};

// GET ORGANIZATIONS ////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
interface GetOrganizationsProps extends FetchProps {
  setLoadedData: React.Dispatch<
    React.SetStateAction<AzumeFinOrganizationData[]>
  >;
}

export const getOrganizations = async (props: GetOrganizationsProps) => {
  const { sendRequest, auth, setLoadedData } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/azume-financial/organizations`;
    const responseData: {
      data: AzumeFinOrganizationData[];
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setLoadedData(responseData.data || []);
  } catch (err) {}
};

// GET BANK ACCOUNTS ////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
interface GetBankAccountsProps extends FetchProps {
  setLoadedData: React.Dispatch<
    React.SetStateAction<AzumeFinBankAccountData[]>
  >;
}

export const getBankAccounts = async (props: GetBankAccountsProps) => {
  const { sendRequest, auth, setLoadedData } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/azume-financial/bank-accounts`;
    const responseData: {
      data: AzumeFinBankAccountData[];
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setLoadedData(responseData.data || []);
  } catch (err) {}
};

// GET COST CENTERS /////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
interface GetCostCentersProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<AzumeFinCostCenterData[]>>;
}

export const getCostCenters = async (props: GetCostCentersProps) => {
  const { sendRequest, auth, setLoadedData } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/azume-financial/cost-centers`;
    const responseData: {
      data: AzumeFinCostCenterData[];
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setLoadedData(responseData.data || []);
  } catch (err) {}
};

// GET CATEGORIES ///////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
interface GetCategoriesProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<AzumeFinCategoryData[]>>;
}

export const getCategories = async (props: GetCategoriesProps) => {
  const { sendRequest, auth, setLoadedData } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/azume-financial/categories`;
    const responseData: {
      data: AzumeFinCategoryData[];
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setLoadedData(responseData.data || []);
  } catch (err) {}
};
