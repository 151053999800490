import React, { useReducer, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";

import { InputDispState, InputDispAction } from "./Input";
import { NumberFormatCustomProps } from "./InputCardNumber";
import { validate } from "../../util/validators";

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, name, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={0}
    />
  );
}

const inputReducer = (state: InputDispState, action: InputDispAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: /^[0-9]*$/.test(action.val)
          ? action.val
          : action.val?.replace(/\D/g, "") || "",
        isValid: validate(
          /^[0-9]*$/.test(action.val)
            ? action.val
            : action.val?.replace(/\D/g, "") || "",
          action.validators
        ),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "UNTOUCH":
      return {
        ...state,
        isTouched: false,
      };
    default:
      return state;
  }
};

export interface InputPrefixOutlinedIntProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  onBlur?: () => void;
  helperText: string;
  type: string;
  initialValue?: string;
  initialValid?: boolean;
  variant?: "standard" | "filled" | "outlined";
  forceError?: boolean;
  placeholder?: string;
  disabled?: boolean;
  reset?: boolean;
  updateInitValue?: boolean;
  reinitialize?: boolean;
  prefix: string;
  validators: { type: string; val?: number }[];
  style?: {};
}

export const InputPrefixOutlinedInt = (props: InputPrefixOutlinedIntProps) => {
  const {
    id,
    label,
    onInput,
    onBlur = () => {},
    forceError,
    helperText,
    type,
    initialValue,
    initialValid,
    variant,
    placeholder,
    disabled,
    validators,
    reinitialize,
    updateInitValue,
    reset,
    prefix,
    style,
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isTouched: false,
    isValid: initialValid || false,
  });
  const { value, isValid, isTouched } = inputState;

  useEffect(() => {
    onInput(id, value, isValid, label.charAt(0).toUpperCase() + label.slice(1));
  }, [id, value, isValid, label, onInput]);

  useEffect(() => {
    if (reset) {
      dispatch({
        type: "CHANGE",
        val: "",
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [reset, reinitialize, initialValue]);

  useEffect(() => {
    if (updateInitValue) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [initialValue, updateInitValue]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: validators,
    });
  };

  const touchHandler = () => {
    dispatch({ type: "TOUCH" });
  };

  return (
    <TextField
      style={style}
      disabled={disabled}
      error={(!isValid && isTouched) || forceError}
      id={id}
      name={id}
      value={value}
      variant={variant}
      onChange={changeHandler}
      onBlur={() => {
        touchHandler();
        onBlur();
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          onBlur();
        }
      }}
      type={type}
      label={label.charAt(0).toUpperCase() + label.slice(1)}
      helperText={forceError ? helperText : !isValid && isTouched && helperText}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ),
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};
