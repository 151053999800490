import { AuthContextProps, SettingsContextData } from "../../shared/data/types";

export const getPriceComponentsValues = (
  owner: string,
  settingsCtx: SettingsContextData,
  systemPower: number,
  modulesQtd: number,
  invQtd: number,
  distance: number,
  location: string,
  invPower: number,
  voltage: number,
  fixation: string,
  roofHight: number,
  service: string | null = null,
  inverterType: "String" | "Micro" = "String",
  preVendor: string | null
) => {
  const serviceCosts: {
    name: string;
    value: number;
    isRevenue?: boolean;
    componentId: string;
  }[] = [];

  const costPercentages: {
    name: string;
    value: number;
    ogValue?: number;
    type: SettingsContextData["autoPricingDetailed"]["pricingComponents"][0]["type"];
    isRevenue?: boolean;
    isCommission?: boolean;
    comissionPermission?: "NONE" | "VIEW" | "EDIT";
    componentId: string;
  }[] = [];

  let pricingComps =
    settingsCtx?.autoPricingDetailed?.pricingComponents?.filter((c) => {
      return (!service && !c.service) || (!!service && service === c.service);
    }) || [];

  let restrictions =
    settingsCtx?.autoPricingDetailed?.restrictions?.filter((r) => {
      return (!service && !r.service) || (!!service && service === r.service);
    }) || [];

  let foundRestriction = restrictions?.find((r) => {
    return r?.locations?.map((l) => l.trim())?.includes(location?.trim());
  });

  if (!foundRestriction) {
    foundRestriction = restrictions?.find((r) => {
      return r?.vendors?.map((v) => v.trim())?.includes(owner?.trim());
    });
  }

  if (foundRestriction) {
    pricingComps =
      pricingComps?.filter((c) => {
        return c.restriction?.trim() === foundRestriction?._id?.trim();
      }) || [];
  } else {
    pricingComps =
      pricingComps?.filter((c) => {
        return !c.restriction;
      }) || [];
  }

  const hasIndividualComission = !!pricingComps?.find((c) => {
    return c.type === "INDIVIDUAL_COMMISSION" && owner === c?.vendor;
  });

  for (const component of pricingComps) {
    let foundValue = 0;
    let shoudCont = true;
    let isCommission: boolean = false;

    if (component?.conditionals) {
      component?.conditionals.forEach((c) => {
        if (c?.type === "VOLTAGE") {
          if (c.logic === "EQUAL") {
            shoudCont = c.numValue === voltage;
          } else if (c.logic === "DIFFERENT") {
            shoudCont = c.numValue !== voltage;
          }
        } else if (c?.type === "FIXATION") {
          if (c.logic === "EQUAL") {
            shoudCont = c.strValue === fixation;
          } else if (c.logic === "DIFFERENT") {
            shoudCont = c.strValue !== fixation;
          }
        } else if (c?.type === "INVERTER_TYPE") {
          if (c.logic === "EQUAL") {
            shoudCont = c.strValue === inverterType;
          } else if (c.logic === "DIFFERENT") {
            shoudCont = c.strValue !== inverterType;
          }
        } else if (c?.type === "VENDOR" && !!owner) {
          isCommission = true;
          if (c.logic === "EQUAL") {
            shoudCont = c.strValue === owner;
          } else if (c.logic === "DIFFERENT") {
            shoudCont = c.strValue !== owner;
          }
        } else if (c?.type === "PRE_VENDOR" && !!preVendor) {
          if (c.logic === "EQUAL") {
            shoudCont = c.strValue === preVendor;
          } else if (c.logic === "DIFFERENT") {
            shoudCont = c.strValue !== preVendor;
          }
        } else {
          shoudCont = false;
        }
      });
    }

    if (shoudCont) {
      foundValue =
        component?.rules
          ?.sort((a, b) => {
            return (a?.power || 0) - (b?.power || 0);
          })
          ?.filter((ap) => {
            if (!component?.range) {
              if (component.type !== "PER_MODULE") {
                return systemPower <= ap.power;
              } else {
                return modulesQtd <= ap.power;
              }
            } else {
              if (component.range === "KWP") {
                return systemPower <= ap.power;
              } else if (component.range === "MODULES") {
                return modulesQtd <= ap.power;
              } else if (component.range === "INVERTERS") {
                return invQtd <= ap.power;
              } else if (component.range === "KW") {
                return invPower <= ap.power;
              } else if (component.range === "KM") {
                return distance <= ap.power;
              } else if (component.range === "ROOF_HIGHT") {
                return roofHight <= ap.power;
              } else {
                return systemPower <= ap.power;
              }
            }
          })
          ?.slice(0, 1)?.[0]?.value || 0;

      if (component?.type === "FIXED") {
        const serviceCost = foundValue;
        serviceCosts.push({
          name: component?.name,
          value: serviceCost,
          isRevenue: component?.isRevenue || false,
          componentId: component._id || "",
        });
      }

      if (component.type === "PER_KWP") {
        const serviceCost = foundValue * systemPower;
        serviceCosts.push({
          name: component?.name,
          value: serviceCost,
          isRevenue: component?.isRevenue || false,
          componentId: component._id || "",
        });
      }

      if (component.type === "PER_MODULE") {
        const serviceCost = foundValue * modulesQtd;
        serviceCosts.push({
          name: component?.name,
          value: serviceCost,
          isRevenue: component?.isRevenue || false,
          componentId: component._id || "",
        });
      }

      if (component.type === "PER_INVERTER") {
        const serviceCost = foundValue * invQtd;
        serviceCosts.push({
          name: component?.name,
          value: serviceCost,
          isRevenue: component?.isRevenue || false,
          componentId: component._id || "",
        });
      }

      if (component.type === "PER_KM" && distance > 0) {
        const serviceCost = foundValue * distance;
        serviceCosts.push({
          name: component?.name,
          value: serviceCost,
          isRevenue: component?.isRevenue || false,
          componentId: component._id || "",
        });
      }

      if (
        component.type === "PERCENTAGE_SERVICE_AND_KIT" ||
        component.type === "PERCENTAGE_SERVICE" ||
        component.type === "PERCENTAGE_KIT" ||
        component.type === "PERCENTAGE_PROFIT" ||
        component.type === "TRADING_MARGIN"
      ) {
        const costPercentage = foundValue;
        costPercentages.push({
          name: component?.name,
          value: costPercentage,
          ogValue: costPercentage,
          type: component.type,
          isRevenue: component?.isRevenue || false,
          isCommission: isCommission,
          comissionPermission: component.comissionPermission || "NONE",
          componentId: component._id || "",
        });
      }

      if (
        component.type === "INDIVIDUAL_COMMISSION" &&
        owner === component?.vendor
      ) {
        const costPercentage = foundValue;
        costPercentages.push({
          name: component?.name,
          value: costPercentage,
          ogValue: costPercentage,
          type: component.type,
          isRevenue: component?.isRevenue || false,
          isCommission: true,
          comissionPermission: component.comissionPermission || "NONE",
          componentId: component._id || "",
        });
      }

      if (component.type === "COMMISSION" && !hasIndividualComission) {
        const costPercentage = foundValue;
        costPercentages.push({
          name: component?.name,
          value: costPercentage,
          ogValue: costPercentage,
          type: component.type,
          isRevenue: component?.isRevenue || false,
          isCommission: true,
          comissionPermission: component.comissionPermission || "NONE",
          componentId: component._id || "",
        });
      }
    }
  }

  return { serviceCosts, costPercentages };
};
