import { makeStyles } from "@material-ui/core/styles";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { visibility } from "html2canvas/dist/types/css/property-descriptors/visibility";

export const MainNavigationStyles = makeStyles((theme) => ({
  scrollRootLeft: {
    position: "fixed",
    zIndex: 500,
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
    zIndex: 500,
    "&--hidden": {
      "& ~ main > div.screen": {
        marginLeft: "25px",
      },
      "& ~ main div.azume-kit__filters": {
        maxWidth: "calc(100vw - 33px)",
      },
      "& ~ main div.azume-kit__actions": {
        width: "calc(100vw - 25px)",
      },
    },
  },
  appBar: {
    zIndex: 500,
    backgroundColor: "#fff",
    color: "#043a50",
    height: "80px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "56px",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  title: {},
  navOpts: {
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: "max-content",
    gridGap: "0.25rem",
    marginLeft: "3.4rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0.5rem",
    },
  },
  navOptBtn: {
    width: "35px",
    height: "35px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all ease-in-out 0.2s",
    position: "relative",
    top: "6.45rem",
    zIndex: 10001,

    [theme.breakpoints.down("sm")]: {
      height: 0,
      width: 0,
      visibility: "hidden",
    },
    "&--hidden": {
      transform: "translateX(-225px)",
    },
    "& svg": {
      transition: "transform ease-in-out 0.2s",
      fontSize: "42px",
      color: "#189ab4",
    },
    "&:hover": {
      backgroundColor: "#189ab4",

      "& svg": {
        color: "#ffffff",
      },
    },
    "&--active": {
      backgroundColor: "#189ab4",
      // boxShadow: "0px 1px 9px -1px rgb(0 0 0 / 30%)",

      "& svg": {
        transition: "transform ease-in-out 0.2s",
        transform: "rotate(180deg)",
        color: "#ffffff",
      },
    },
  },
  navOptBtnMobile: {
    width: "25px",
    height: "25px",
    backgroundColor: "#189ab4",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all 0.2s",
    position: "absolute",
    right: "1rem",
    top: "1rem",
    "&--active": {
      backgroundColor: "#05445e",
      transform: "rotate(180deg)",
    },
    "& svg": {
      fontSize: "28px",
      color: "#fff",
    },
  },
  desktopNavlinks: {},
  mobileNavlinks: {
    width: "250px",
    height: "100%",
  },
  navbarContentPadding: {
    padding: "0 1.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem",
    },
  },
  formExtRoot: {
    position: "relative",
  },
  formRoot: {
    position: "relative",
    width: "380px",
    padding: "20px 10px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
    "& > *": {
      margin: theme.spacing(1),
      width: "365px",
      [theme.breakpoints.down("xs")]: {
        width: "280px",
      },
    },
  },
}));
