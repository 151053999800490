import React, { useReducer, useEffect } from "react";
import TextField from "@material-ui/core/TextField";

import { InputDispState, InputDispAction } from "./Input";
import { validate } from "../../util/validators";

const inputReducer = (state: InputDispState, action: InputDispAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

export interface InputDefinedProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  type: string;
  initialValue?: string;
  variant?: "standard" | "filled" | "outlined";
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
  reset?: boolean;
  reinitialize?: boolean;
  optional?: boolean;
  validators: { type: string; val?: number }[];
  style?: {};
}

export const InputDefined = (props: InputDefinedProps) => {
  const {
    id,
    label,
    onInput,
    type,
    initialValue,
    variant,
    multiline,
    rows,
    placeholder,
    disabled,
    validators,
    reinitialize,
    reset,
    optional,
    style,
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isTouched: false,
    isValid: optional ? true : !!initialValue || false,
  });
  const { isValid, isTouched } = inputState;

  useEffect(() => {
    onInput(
      id,
      initialValue,
      optional ? true : !!initialValue,
      label.charAt(0).toUpperCase() + label.slice(1)
    );
  }, [id, initialValue, onInput, label, isValid, optional]);

  useEffect(() => {
    if (reset) {
      dispatch({
        type: "CHANGE",
        val: "",
        validators: validators || [{ type: "REQUIRE" }],
      });
    }

    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators || [{ type: "REQUIRE" }],
      });
    }
  }, [reset, reinitialize, initialValue]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: validators,
    });
  };

  const touchHandler = () => {
    dispatch({ type: "TOUCH" });
  };

  return (
    <TextField
      style={style}
      disabled={disabled}
      error={!isValid && isTouched}
      id={id}
      name={id}
      variant={variant}
      label={label.charAt(0).toUpperCase() + label.slice(1)}
      multiline={multiline}
      rows={multiline && rows}
      placeholder={placeholder}
      inputProps={{
        onChange: changeHandler,
        onBlur: touchHandler,
        value: initialValue,
        type: type,
      }}
    />
  );
};
