import Popover from "@material-ui/core/Popover";
import React, { Fragment, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { InputCalendar } from "../../shared/components/FormElements/InputCalendar";
import { InputSwitch } from "../../shared/components/FormElements/InputSwitch";
import { InputPrefixOutlinedFloat } from "../../shared/components/FormElements/InputPrefixOutlinedFloat";
import { InputSelectRequired } from "../../shared/components/FormElements/InputSelectRequired";
import { useForm } from "../../shared/hooks/formHook";
import { PopoverFormStyles } from "./PopoverFormStyles";
import { editSaleStatus } from "../api/salesFunnelAPI";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { dateFormatFromISOString } from "../../shared/util/dateFormatFromISOString";
import { VALIDATOR_REQUIRE, VALIDATOR_MIN } from "../../shared/util/validators";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextData,
} from "../../shared/data/types";

interface PopoverFormClosedSalePropChoiceProps {
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  itemId: string;
  item: KanbanFilledData["items"][0];
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  settingsCtx: SettingsContextData;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setActiveItem?: React.Dispatch<React.SetStateAction<string>>;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const PopoverFormClosedSalePropChoice = (
  props: PopoverFormClosedSalePropChoiceProps
) => {
  const {
    item,
    anchorEl,
    setAnchorEl,
    auth,
    sendRequest,
    setKanbanData,
    setActiveItem,
    type,
    settingsCtx,
    setUpdateColumnsTotals,
  } = props;
  const customerProposals = item?.customer?.proposals || [];
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const [chosenProp, setChosenProp] = useState<string>(
    customerProposals?.slice(-1)?.[0]?.id || "none"
  );
  const [dueDate, setDueDate] = useState<string>(new Date().toISOString());
  const [finishedDate, setFinishedDate] = useState<string>(
    new Date().toISOString()
  );
  const { formState, inputHandler } = useForm({}, true);

  useEffect(() => {
    if (customerProposals?.length > 0) {
      setChosenProp(customerProposals?.slice(-1)?.[0]?.id);
    } else {
      setChosenProp("none");
    }
  }, [customerProposals]);

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenProp(e.target.value);
  };

  const selectectDueDateHandler = (date: string) => {
    setDueDate(date);
  };

  const selectectFinishedDateHandler = (date: string) => {
    setFinishedDate(date);
  };

  const confirmHandler = () => {
    editSaleStatus({
      sendRequest,
      auth,
      status: "CLOSED",
      approvedProposal: !!chosenProp && chosenProp !== "none" ? chosenProp : "",
      itemId: item?._id,
      dueDate: dateFormatFromISOString(dueDate),
      finishedDate: dateFormatFromISOString(finishedDate),
      setKanbanData,
      toBeRemovedItem: item,
      power: +formState?.inputs?.power?.value || 0,
      ucType: (formState?.inputs?.ucType?.value as "Residencial") || "",
      confirmedAmount: +formState?.inputs?.confirmedAmount?.value || undefined,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([item.kanbanData?.column, "0"]);
      }
    });
    setAnchorEl(null);
  };

  const chosenPropDoc = customerProposals?.find(
    (prop) => prop.id === chosenProp
  );

  return (
    <Fragment>
      <Popover
        id={item?._id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Selecionar proposta aprovada
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={chosenProp}
              onChange={changeValueHandler}
            >
              {customerProposals?.map((option, i) => {
                return (
                  <MenuItem key={`opt-${i}`} value={option?.id}>
                    {`#${
                      option.count?.toLocaleString("pt-BR", {
                        minimumIntegerDigits: 5,
                        useGrouping: false,
                      }) || "000000"
                    } - ${addThousandSeparator(
                      option?.realSystemData?.power || 0
                    )} kWp - R$ ${addThousandSeparator(
                      option?.revenueData?.finalPrice || 0
                    )}`}
                  </MenuItem>
                );
              })}
              <MenuItem key="none" value="none">
                Nenhuma Proposta
              </MenuItem>
            </Select>
          </FormControl>
          {chosenProp === "none" && (
            <div className="form-inputs-grid-1fr-1fr">
              <InputPrefixOutlinedFloat
                id="power"
                type="text"
                label="Potência"
                prefix="kWp"
                variant="outlined"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_MIN(0.01)]}
                initialValue={""}
                helperText="Potência do Sistema FV."
                reinitialize
              />
              <InputSelectRequired
                id="ucType"
                label="Tipo de UC"
                variant="outlined"
                onInput={inputHandler}
                options={[
                  "Residencial",
                  "Comercial",
                  "Industrial",
                  "Rural",
                  "Autoconsumo Remoto",
                ]}
                initialValue={"Residencial"}
                updateInitValue
                forceError={formState.inputs?.ucType?.forceError}
              />
            </div>
          )}
          {auth.signupPlan === "ENTERPRISE" && (
            <InputCalendar
              id="finishedDate"
              label="Data de Finalização"
              format="dd/MM/yy"
              disableFuture
              initialvalue={finishedDate}
              selectedEarlierTimePeriodHandler={selectectFinishedDateHandler}
            />
          )}
          {settingsCtx?.preferences?.kanbans?.autoCreateProjAndInst &&
            !!chosenProp &&
            auth.signupPlan === "ENTERPRISE" && (
              <InputSwitch
                id="enableDueDate"
                label={`Possui Prazo de Entrega?`}
                title="Habilite esta opção se a instalação possui data de entrega definida."
                initialValue={true}
                color="primary"
                onInput={inputHandler}
                updateInitValue
              />
            )}
          {settingsCtx?.preferences?.kanbans?.autoCreateProjAndInst &&
            formState?.inputs?.enableDueDate?.value &&
            !!chosenProp &&
            auth.signupPlan === "ENTERPRISE" && (
              <InputCalendar
                id="dueDate"
                label="Data de Entrega"
                format="dd/MM/yy"
                disablePast
                initialvalue={dueDate}
                selectedEarlierTimePeriodHandler={selectectDueDateHandler}
              />
            )}
          {item?.type === "SALES" && (
            <InputPrefixOutlinedFloat
              id="confirmedAmount"
              type="text"
              label="Conirmar Valor de Venda"
              prefix="R$"
              variant="outlined"
              onInput={inputHandler}
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MIN(0.01)]}
              initialValue={
                chosenPropDoc?.revenueData?.finalPrice?.toString() ||
                item?.amount?.toString() ||
                ""
              }
              helperText="Confirmação do valor de venda."
              updateInitValue
              reinitialize
            />
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={
              !chosenProp || (chosenProp === "none" && !formState?.isValid)
            }
            onClick={() => {
              confirmHandler();
              setAnchorEl(null);
            }}
          >
            DAR GANHO
          </Button>
        </form>
      </Popover>
    </Fragment>
  );
};
